import AvatarIvelin from "assets/images/avatars/ivelin.jpeg";
import AvatarHusk from "assets/images/avatars/elon_husk.png";
import AvatarJane from "assets/images/avatars/jane.png";
import AvatarJohn from "assets/images/avatars/john_arnold.jpg";
import AvatarMichael from "assets/images/avatars/michael.png";
import AvatarPeter from "assets/images/avatars/peter.png";
import AvatarTaylor from "assets/images/avatars/taylor.png";

const scenarioCards = [
  {
    color: "primary",
    title: "Cold Call",
    description: "Practice initiating calls with potential clients.",
    duedate: {
      date: "3.21",
      text: "Average Score",
    },
    avatars: [AvatarHusk, AvatarJane, AvatarMichael],
    selectedRoleplay: "cold_call_jimmy_easy",
    categories: ["Recommended for you", "Core Sales Techniques"],
    authLevels: ["general", "Entr Tech", "skylar"],
    multiverseCategories: ["Core Sales Techniques"],
  },
  {
    color: "secondary",
    title: "Cold Call",
    description: "Cold Call a Chief Data Officer",
    duedate: {
      date: "2.95",
      text: "Average Score",
    },
    avatars: [AvatarMichael, AvatarPeter, AvatarTaylor],
    selectedRoleplay: "cold_call_multiverse_cdo",
    categories: ["Recommended for you"],
    authLevels: ["multiverse"],
    multiverseCategories: ["Recommended for you"],
  },
  {
    color: "secondary",
    title: "CFO",
    description: "Cold Call a Chief Financial Officer",
    avatars: [AvatarMichael],
    selectedRoleplay: "cold_call_paypro_demo",
    categories: ["Recommended for you"],
    authLevels: ["PayPro Global"],
  },
  {
    color: "secondary",
    title: "CFO - new",
    description: "Cold Call using a situation",
    avatars: [AvatarPeter],
    selectedRoleplay: "cold_call_paypro_demo_situation",
    categories: ["Recommended for you"],
    authLevels: ["PayPro Global"],
  },
  {
    color: "secondary",
    title: "Head of Payments",
    description: "Cold Call a Head of Payments (Business Banking)",
    avatars: [AvatarPeter],
    selectedRoleplay: "cold_call_fis_demo",
    categories: ["Recommended for you"],
    authLevels: ["FIS Global"],
  },
  {
    color: "secondary",
    title: "Research-based Cold Call",
    description: "Cold Call using research",
    duedate: {
      date: "3.41",
      text: "Average Score",
    },
    avatars: [AvatarJohn, AvatarTaylor],
    selectedRoleplay: "cold_call_multiverse_research_based",
    categories: ["Recommended for you"],
    authLevels: ["multiverse"],
    multiverseCategories: ["Recommended for you"],
  },
  {
    color: "secondary",
    title: "Openers",
    description: "Learn about permission-based openers.",
    avatars: [AvatarJohn, AvatarPeter, AvatarTaylor, AvatarMichael],
    selectedRoleplay: "permission_based_opener",
    categories: ["Recommended for you", "Core Sales Techniques"],
    authLevels: [
      "general",
      "multiverse",
      "Entr Tech",
      "skylar",
      "PayPro Global",
      "FIS Global",
    ],
    multiverseCategories: ["Core Sales Techniques"],
    payproCategories: ["Core Sales Techniques"],
  },
  {
    color: "secondary",
    title: "Cold Call - Sales Leader",
    description: "Cold Call a sales leader",
    duedate: {
      date: "3.52",
      text: "Average Score",
    },
    avatars: [AvatarMichael],
    selectedRoleplay: "cold_call_steve",
    categories: ["Recommended for you"],
    authLevels: ["general", "Entr Tech"],
    multiverseCategories: ["Core Sales Techniques"],
  },
  {
    color: "secondary",
    title: "Gatekeeper",
    description: "Drills to navigate past gatekeepers.",
    avatars: [AvatarJohn, AvatarPeter, AvatarTaylor, AvatarMichael],
    categories: ["Core Sales Techniques"],
    authLevels: ["general"],
    multiverseCategories: ["Core Sales Techniques"],
  },
  {
    color: "secondary",
    title: "Objection Handling",
    description: "Enhance skills in handling objections during calls.",
    avatars: [
      AvatarPeter,
      AvatarJohn,
      AvatarTaylor,
      AvatarMichael,
      AvatarIvelin,
    ],
    categories: ["Core Sales Techniques"],
    authLevels: ["general"],
    multiverseCategories: ["Core Sales Techniques"],
  },
  {
    color: "secondary",
    title: "Pitch Practice",
    description:
      "Refine your sales pitches to ensure clarity and persuasiveness.",
    avatars: [AvatarJane, AvatarJohn, AvatarTaylor],
    categories: ["Advanced Sales Practices"],
    authLevels: ["general"],
    multiverseCategories: ["Core Sales Techniques"],
  },
  {
    color: "secondary",
    title: "Discovery",
    description:
      "Practice ways to uncover customer needs, uncover pain and get to the next step.",
    avatars: [AvatarMichael, AvatarPeter, AvatarIvelin],
    categories: ["Advanced Sales Practices"],
    authLevels: ["general", "multiverse"],
    multiverseCategories: ["Core Sales Techniques"],
  },
  {
    color: "secondary",
    title: "Effective Questioning",
    description: "Develop and practice effective questioning techniques.",
    avatars: [AvatarHusk, AvatarJane, AvatarJohn],
    categories: ["Core Sales Techniques"],
    authLevels: ["general", "multiverse"],
    multiverseCategories: ["Core Sales Techniques"],
  },
  {
    color: "secondary",
    title: "Negotiation Skills",
    description: "Enhance your negotiation skills to close deals successfully.",
    avatars: [AvatarTaylor, AvatarMichael, AvatarPeter],
    categories: ["Core Sales Techniques"],
    authLevels: ["general", "multiverse"],
    multiverseCategories: ["Core Sales Techniques"],
  },
  {
    color: "secondary",
    title: "Customer Onboarding",
    description: "Drills on how to manage relationships.",
    avatars: [AvatarIvelin, AvatarHusk, AvatarJane],
    categories: ["Advanced Sales Practices"],
    authLevels: ["general"],
    multiverseCategories: ["Core Sales Techniques"],
  },
];

export default scenarioCards;
