// useUserManagement.js
import { useEffect, useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import LogRocket from "logrocket";

import {
  setAuthLevel,
  setDisplayName,
  setEmail,
  setSelectedRoleplay,
  setAuthenticated,
  setTotalRoleplaysToday,
  setOrgId,
} from "../features/user/userSlice";
import { AuthContext } from "../services/firebase/AuthContext";

export const useUserManagement = () => {
  const {
    authenticatedCurrentUser,
    authToken,
    authenticatedEmail,
    authenticatedDisplayName,
    getTotalRoleplaysToday,
    doc,
    onSnapshot,
    db,
  } = useContext(AuthContext);

  const enterNameModalRef = useRef(null);

  const dispatch = useDispatch();

  // Once we are logged in, we start tracking the user
  useEffect(() => {
    if (authenticatedCurrentUser) {
      if (process.env.REACT_APP_ENV !== "development") {
        LogRocket.identify(authenticatedCurrentUser.uid, {
          name: authenticatedDisplayName,
          email: authenticatedEmail,
        });
      }

      localStorage.setItem("authToken", authToken);
      console.debug("User management hook triggered for user");
      dispatch(setAuthenticated(true));
      dispatch(setEmail(authenticatedEmail));

      //if a user has a display name, we send it, otherwise we prompt them to add one
      if (authenticatedDisplayName) {
        dispatch(setDisplayName(authenticatedDisplayName));
      } else {
        enterNameModalRef.current.open();
      }

      // Define privileged emails
      const privilegedEmails = new Set([
        "fabdisamed@gmail.com",
        "gulled_mohamed2024@outlook.com",
        "mrism002@gmail.com",
        "ngonidzashemurombe@gmail.com",
        "omar08374@gmail.com",
        "umarjeewa1@gmail.com",
        "danielpetcuiulian@gmail.com",
        "haseebminhas848@gmail.com",
        "kierannagarajah9@gmail.com",
        "lincolnimade@gmail.com",
        "m.elmi2005@gmail.com",
      ]);

      const entrTechEmails_august = new Set([
        "jack@entr-tech.com",
        "aaron.s.deo@hotmail.co.uk",
        "andre.meehan@gmail.com",
        "danmur94@icloud.com",
        "emmanuelyankson@gmail.com",
        "ephraimofori@icloud.com",
        "hamzaabdulkadir.m@gmail.com",
        "jorsek457@hotmail.com",
        "julian_3000s@outlook.com",
        "mzfahem11@gmail.com",
        "sofiemsgina101@gmail.com",
        "Khalid_hassan2009@live.com",
        "test@entr-tech.com",
      ]);

      let authLevel = "general";
      let orgId = null;
      if (privilegedEmails.has(authenticatedEmail)) {
        authLevel = "Entr Tech";
        orgId = "org_entr_tech";
      } else if (entrTechEmails_august.has(authenticatedEmail)) {
        authLevel = "Entr Tech";
        orgId = "org_entr_tech_august";
      } else {
        const emailDomain = authenticatedEmail.split("@")[1];
        if (emailDomain === "multiverse.io") {
          authLevel = "multiverse";
          orgId = "org_multiverse";
          dispatch(setSelectedRoleplay("cold_call_multiverse_cdo"));
        }
        if (emailDomain === "getskylar.com") {
          authLevel = "skylar";
          orgId = "org_skylar";
          dispatch(setSelectedRoleplay("cold_call_skylar_demo"));
        }
        if (emailDomain === "payproglobal.com") {
          authLevel = "PayPro Global";
          orgId = "org_paypro";
          dispatch(setSelectedRoleplay("cold_call_paypro_demo"));
        }
        if (emailDomain === "fisglobal.com") {
          authLevel = "FIS Global";
          orgId = "org_fis";
          dispatch(setSelectedRoleplay("cold_call_fis_demo"));
        }
      }

      dispatch(setAuthLevel(authLevel));
      dispatch(setOrgId(orgId));
    } else {
      //if you log out, before logging in again, we need to reset the user
      localStorage.removeItem("authToken");
      dispatch(setAuthenticated(false));
      dispatch(setEmail("demo@example.com"));
      dispatch(setDisplayName("Unknown"));
      dispatch(setSelectedRoleplay("cold_call_jimmy_easy"));
      dispatch(setAuthLevel("general"));
      dispatch(setTotalRoleplaysToday(0));
      dispatch(setOrgId(null));
    }
  }, [authenticatedCurrentUser, dispatch, authenticatedEmail]);

  useEffect(() => {
    const fetchTotalRoleplays = async () => {
      if (authenticatedCurrentUser) {
        //check and reset the number of roleplays today
        await getTotalRoleplaysToday(authenticatedCurrentUser.uid);
      }
    };

    fetchTotalRoleplays();
  }, [authenticatedCurrentUser, dispatch]);

  useEffect(() => {
    if (!authenticatedCurrentUser) return;
    //listener for changing and reseting the number of roleplays today

    const userRef = doc(db, "users", authenticatedCurrentUser.uid);
    const unsubscribe = onSnapshot(
      userRef,
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          const totalRoleplays = data.totalRoleplaysToday || 0;
          dispatch(setTotalRoleplaysToday(totalRoleplays));
        }
      },
      (error) => {
        console.error("Error fetching user data:", error);
        // Optionally, handle the error more robustly here, e.g., by setting an error state,
        // showing a notification to the user, or retrying the subscription.
      },
    );

    return () => unsubscribe(); // Cleanup subscription on unmount or when authenticatedCurrentUser changes
  }, [authenticatedCurrentUser?.uid, dispatch]);

  return { authenticatedCurrentUser, enterNameModalRef };
};
