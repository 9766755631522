export const ORG_DOMAIN_MAPPING = {
  "multiverse.io": "org_multiverse",
  "fisglobal.com": "org_fis",
  "payproglobal.com": "org_paypro",
};

export const SPECIFIC_EMAIL_MAPPING = {
  "example@specific.com": "org_specific",
  "jack@entr-tech.com": "org_entr_tech",
  "fabdisamed@gmail.com": "org_entr_tech",
  "gulled_mohamed2024@outlook.com": "org_entr_tech",
  "mrism002@gmail.com": "org_entr_tech",
  "ngonidzashemurombe@gmail.com": "org_entr_tech",
  "omar08374@gmail.com": "org_entr_tech",
  "umarjeewa1@gmail.com": "org_entr_tech",
  "danielpetcuiulian@gmail.com": "org_entr_tech",
  "haseebminhas848@gmail.com": "org_entr_tech",
  "kierannagarajah9@gmail.com": "org_entr_tech",
  "lincolnimade@gmail.com": "org_entr_tech",
  "m.elmi2005@gmail.com": "org_entr_tech",
  "test@entr-tech.com": "org_entr_tech",
  "aaron.s.deo@hotmail.co.uk": "org_entr_tech_august",
  "andre.meehan@gmail.com": "org_entr_tech_august",
  "danmur94@icloud.com": "org_entr_tech_august",
  "emmanuelyankson@gmail.com": "org_entr_tech_august",
  "ephraimofori@icloud.com": "org_entr_tech_august",
  "hamzaabdulkadir.m@gmail.com": "org_entr_tech_august",
  "jorsek457@hotmail.com": "org_entr_tech_august",
  "julian_3000s@outlook.com": "org_entr_tech_august",
  "mzfahem11@gmail.com": "org_entr_tech_august",
  "sofiemsgina101@gmail.com": "org_entr_tech_august",
  "Khalid_hassan2009@live.com": "org_entr_tech_august",
};
